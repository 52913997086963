import {
  AppSettingsAlt,
  DeveloperBoardOutlined,
  GroupOutlined,
  HowToVoteOutlined,
  PollOutlined,
  SportsKabaddiOutlined,
} from '@mui/icons-material'

import Diversity2Icon from '@mui/icons-material/Diversity2'
import * as React from 'react'
import ROUTES from '@constant/Routes'
import { MainMenuItem } from '@type/common'
import LegalLinks from '@constant/LegalLinks'

export const CONGRESS_MEMBER_MENU_ITEMS: MainMenuItem = {
  name: 'Congress Challenger',
  path: '',
  domain: process.env.NEXT_PUBLIC_CONGRESS_MEMBER_URL,
  icon: <AppSettingsAlt />,
  newPageMenu: true,
  subItems: [
    {
      name: 'Congress Challengers',
    },
    {
      icon: <DeveloperBoardOutlined />,
      name: 'Congress Members',
      path: ROUTES.CongressMembers,
    },
    {
      icon: <GroupOutlined />,
      name: 'My Representatives',
      path: ROUTES.MyRepresentatives,
    },
    {
      icon: <Diversity2Icon />,
      name: 'Congressional Committees',
      path: ROUTES.CommitteeList,
      admin: true,
    },
    {
      icon: <GroupOutlined />,
      name: 'Unveil Senators',
      path: ROUTES.UnveilSenators,
      hideMenu: true,
    },
    {
      icon: <GroupOutlined />,
      name: 'Unveil Representatives',
      path: ROUTES.UnveilRepresentatives,
      hideMenu: true,
    },
    {
      icon: <SportsKabaddiOutlined />,
      name: 'Challengers',
      path: ROUTES.CongressChallengers,
    },
    {
      name: 'Add US Senate',
      path: ROUTES.AddSenatorCongressChallenger,
      hideMenu: true,
    },
    {
      name: 'US Senate Detail',
      path: ROUTES.SenatorCongressChallengerDetail,
      hideMenu: true,
    },
    {
      name: 'Edit Representative',
      path: ROUTES.RepresentativeCongressChallengerEdit,
      hideMenu: true,
    },
    {
      name: 'Add Representative',
      path: ROUTES.AddRepresentativeCongressChallenger,
      hideMenu: true,
    },
    {
      name: 'US Representative Detail',
      path: ROUTES.RepresentativeCongressChallengerDetail,
      hideMenu: true,
    },
    {
      name: 'Edit US Senate',
      path: ROUTES.SenatorCongressChallengerEdit,
      hideMenu: true,
    },
    {
      icon: <HowToVoteOutlined />,
      name: 'Voting',
      path: `${ROUTES.CongressChallengerVoting}?new=1`,
    },
    {
      icon: <PollOutlined />,
      name: 'Primary Voters',
      path: ROUTES.PrimaryVotes,
      admin: true,
    },
    {
      name: 'Primary Voters Add',
      path: ROUTES.AddPrimaryVotes,
      hideMenu: true,
    },
    {
      name: 'Primary Voters Edit',
      path: ROUTES.PrimaryVotesEdit,
      hideMenu: true,
    },
    {
      name: 'Primary Voters Add',
      path: ROUTES.PrimaryVotesDetail,
      hideMenu: true,
    },
  ],
}

export const congressMemberConfig = {
  name: 'Congress Members',
  logo: 'https://d37tchdccajyhz.cloudfront.net/static/congress-hq-logo.png',
  gaCode: process.env.NEXT_PUBLIC_GTM_CODE,
  privacyPolicy: LegalLinks.PrivacyPolicy,
  marketingVideoId: 'KKTU6zDYrBw',
  marketingIntroVideoId: process.env.NEXT_PUBLIC_MARKETING_INTRO_VIDEO_ID,
  favicon: {
    32: 'congresshq-favicon.ico',
    16: 'congresshq-favicon.ico',
    apple: 'congresshq-favicon.ico',
  },
  theme: {
    primary: {
      main: '#7F00FF',
      dark: '#4C096F',
    },
    buttonGradients: {
      gradientStart: '#4AA0FF',
      gradientEnd: '#025CC0',
    },
  },
  fixesMenuItems: {
    ...CONGRESS_MEMBER_MENU_ITEMS,
    subItems: CONGRESS_MEMBER_MENU_ITEMS.subItems.slice(1),
  },
  homePage: 'CONGRESS_MEMBER',
  paymentMenu: false,
}
